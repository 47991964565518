export default function MyFooter() {
	return (
		<footer>
			<a
				href='https://icons8.com'
				rel='noopener noreferrer'
				target='_blank'>
				<p>
					All icons by Icons8
				</p>
			</a>
		</footer>
	);
}
